"use strict";
/// <reference types="jquery"/>
/// <reference types="fancybox"/>
exports.__esModule = true;
exports.ModalMedia = void 0;
require("jquery.fancybox");
require("jquery.fancybox/source/jquery.fancybox.css");
var ModalMedia = /** @class */ (function () {
    function ModalMedia(config) {
        var mediaOptions = {
            type: 'iframe',
            helpers: {
                media: true
            }
        };
        this.elementsSelector = config.elementsSelector;
        if (config.options) {
            this.options = config.options;
        }
        this.options = jQuery.extend(true, this.options, mediaOptions);
    }
    ModalMedia.prototype.run = function () {
        jQuery(this.elementsSelector).fancybox(this.options);
    };
    return ModalMedia;
}());
exports.ModalMedia = ModalMedia;
