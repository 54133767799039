"use strict";
/// <reference types="jquery"/>
/// <reference types="fancybox"/>
exports.__esModule = true;
exports.ModalForm = void 0;
require("jquery.fancybox");
require("jquery.fancybox/source/jquery.fancybox.css");
var form_validation_1 = require("./form.validation");
var ModalForm = /** @class */ (function () {
    function ModalForm(config) {
        var _this = this;
        var mediaOptions = {
            wrapCSS: 'fancybox-form',
            fitToView: true,
            autoSize: false,
            autoHeight: true,
            afterShow: function () {
                var container = document.querySelector('.fancybox-form');
                _this.form = container.querySelector('form');
                _this.initValidation();
            }
        };
        this.elementsSelector = config.elementsSelector;
        if (config.options) {
            this.options = config.options;
        }
        this.options = jQuery.extend(true, this.options, mediaOptions);
    }
    ModalForm.prototype.run = function () {
        jQuery(this.elementsSelector).fancybox(this.options);
    };
    ModalForm.prototype.initValidation = function () {
        if (this.form !== null) {
            new form_validation_1.FormValidation({
                element: this.form,
                options: {
                    ignore: '.ignore',
                }
            }).run();
        }
    };
    return ModalForm;
}());
exports.ModalForm = ModalForm;
