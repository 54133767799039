"use strict";
// src/ts/form.validation.ts
exports.__esModule = true;
exports.FormValidation = void 0;
/// <reference types="jquery"/>
/// <reference types="jquery.validation"/>
require("bootstrap/js/dist/tooltip");
require("jquery-validation");
require("jquery-validation/dist/localization/messages_ru");
var FormValidation = /** @class */ (function () {
    function FormValidation(config) {
        this.element = config.element;
        if (config.options) {
            this.options = config.options;
        }
    }
    FormValidation.prototype.run = function () {
        this.validator = jQuery(this.element).validate(this.options);
    };
    FormValidation.prototype.destroy = function () {
        this.validator.destroy();
    };
    return FormValidation;
}());
exports.FormValidation = FormValidation;
