"use strict";
/// <reference types="jquery"/>
/// <reference types="bootstrap"/>
exports.__esModule = true;
exports.Collapse = void 0;
require("bootstrap/js/dist/collapse");
var Collapse = /** @class */ (function () {
    function Collapse(config) {
        this.collapseContainer = config.collapseContainer;
        this.togglerSelector = config.togglerSelector;
    }
    Collapse.prototype.getCollapseContainer = function () {
        return this.collapseContainer;
    };
    Collapse.prototype.run = function () {
        var _this = this;
        jQuery(this.togglerSelector).on('click', function (event) {
            event.preventDefault();
            _this.toggle();
        });
        this.trackEvents();
    };
    Collapse.prototype.show = function () {
        jQuery(this.collapseContainer).collapse('show');
    };
    Collapse.prototype.hide = function () {
        jQuery(this.collapseContainer).collapse('hide');
    };
    Collapse.prototype.toggle = function () {
        jQuery(this.collapseContainer).collapse('toggle');
    };
    Collapse.prototype.trackEvents = function () {
    };
    return Collapse;
}());
exports.Collapse = Collapse;
