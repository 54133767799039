"use strict";
/// <reference types="yandex-maps"/>
exports.__esModule = true;
exports.YMaps = void 0;
var YMaps = /** @class */ (function () {
    function YMaps(config) {
        this.container = config.container;
        if (config.options) {
            this.options = config.options;
        }
    }
    YMaps.prototype.run = function () {
        var _this = this;
        var self = this;
        ymaps.ready().then(function () {
            self.instance = new ymaps.Map(_this.container, _this.options);
            _this.instance.behaviors.disable('scrollZoom');
            var placemark = new ymaps.Placemark([54.428431, 30.284849], {
                balloonContent: 'Аэродром Оршанского авиаремонтного завода, Республика Беларусь 211004, Витебская обл., Оршанский р-н, г.п. Болбасово, ул. Заводская, 1, офис 323.'
            }, {
                preset: "islands#blueDotIcon"
            });
            _this.instance.geoObjects.add(placemark);
            _this.instance.panTo(placemark.geometry.getCoordinates());
        });
        return self;
    };
    YMaps.prototype.enableBehaviors = function (behavior) {
        console.log(this.instance);
        this.instance.behaviors.enable(behavior);
    };
    YMaps.prototype.disableBehaviors = function (behavior) {
        console.log('disable');
        this.instance.behaviors.disable(behavior);
    };
    YMaps.prototype.addPlacemark = function () {
        var placemark = new ymaps.Placemark([55.756462, 37.650262], {
            balloonContent: 'Helicopter Service'
        }, {
            preset: "islands#blueDotIcon"
        });
        this.instance.geoObjects.add(placemark);
        this.instance.panTo(placemark.geometry.getCoordinates());
    };
    return YMaps;
}());
exports.YMaps = YMaps;
