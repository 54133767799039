"use strict";
// src/ts/menu.ts
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.Menu = void 0;
/// <reference types="jquery"/>
/// <reference types="bootstrap"/>
var collapse_1 = require("./collapse");
var Menu = /** @class */ (function (_super) {
    __extends(Menu, _super);
    function Menu(config) {
        var _this = _super.call(this, {
            collapseContainer: config.collapseContainer,
            togglerSelector: config.togglerSelector
        }) || this;
        _this.isSubmenuCollapsed = (config.isSubmenuCollapsed) ? true : false;
        _this.expandedMenuItemSelector = (config.expandedMenuItemSelector) ? config.expandedMenuItemSelector : '.menu__expanded-item';
        _this.expandedMenuContainerSelector = (config.expandedMenuContainerSelector) ? config.expandedMenuContainerSelector : '.menu__expanded_container';
        _this.submenuContainerSelector = (config.submenuContainerSelector) ? config.submenuContainerSelector : '.submenu__container';
        return _this;
    }
    Menu.prototype.run = function () {
        _super.prototype.run.call(this);
        if (this.isSubmenuCollapsed) {
            this.initSubmenu();
        }
    };
    Menu.prototype.initSubmenu = function () {
        var instance = this;
        jQuery(this.expandedMenuItemSelector).on('click', function (event) {
            event.preventDefault();
            jQuery(this).next(instance.submenuContainerSelector).toggleClass('d-none d-block');
        });
    };
    Menu.prototype.trackEvents = function () {
        var _this = this;
        jQuery('.navbar-nav__drop').on('click', function (event) {
            jQuery(this).next('.nav-submenu').toggle();
        });
        jQuery('.navbar-collapse-menu-close').on('click', function (event) {
            _this.hide();
        });
        jQuery(this.getCollapseContainer()).on('show.bs.collapse', function (event) {
            _this.onShowBsCollapse(event);
        });
        jQuery(this.getCollapseContainer()).on('shown.bs.collapse', function (event) {
            _this.onShownBsCollapse(event);
        });
        jQuery(this.getCollapseContainer()).on('hide.bs.collapse', function (event) {
            _this.onHideBsCollapse(event);
        });
    };
    Menu.prototype.onShowBsCollapse = function (event) {
        jQuery('body').addClass('menu__opened');
        jQuery('<div/>')
            .addClass('overlay')
            .appendTo('body');
    };
    Menu.prototype.onShownBsCollapse = function (event) {
        jQuery(this.getCollapseContainer()).height(jQuery(window).height());
    };
    Menu.prototype.onHideBsCollapse = function (event) {
        jQuery('body').removeClass('menu__opened');
        jQuery('.overlay').remove();
    };
    return Menu;
}(collapse_1.Collapse));
exports.Menu = Menu;
