"use strict";
// src/ts/main.ts
exports.__esModule = true;
/// <reference types="jquery"/>
var application_1 = require("./application");
require("../scss/main.scss");
jQuery(function () {
    var application = new application_1.Application();
    application.run();
    jQuery('.ap__more').on('click', function (event) {
        event.preventDefault();
        jQuery(this).closest('.ap__item').toggleClass('active');
    });
});
