"use strict";
// src/ts/application.ts
exports.__esModule = true;
exports.Application = void 0;
require("core-js/stable/dom-collections/for-each");
var ease_component_1 = require("ease-component");
var menu_1 = require("./menu");
var slider_1 = require("./slider");
var modal_1 = require("./modal");
var ymaps_1 = require("./ymaps");
var scroll_to_1 = require("./scroll.to");
var form_validation_1 = require("./form.validation");
var modal_media_1 = require("./modal.media");
var modal_form_1 = require("./modal.form");
var static_assets_1 = require("./static.assets");
var Application = /** @class */ (function () {
    function Application() {
        this.generalModalTplOptions = {
            error: '<p class="fancybox-error">Ошибка загрузки данных.<br/>Пожалуйста попробуйте позже.</p>',
            closeBtn: '<a title="Закрыть" class="fancybox-item fancybox-close" href="javascript:;"></a>',
            next: '<a title="Следующий" class="fancybox-nav fancybox-next" href="javascript:;"><span></span></a>',
            prev: '<a title="Предыдущий" class="fancybox-nav fancybox-prev" href="javascript:;"><span></span></a>'
        };
    }
    Application.prototype.run = function () {
        this.initMenu();
        this.initSlider();
        this.initModal();
        this.initModalMedia();
        this.initModalForm();
        this.initYmaps();
        this.initScrollTo();
        this.initFromValidation();
        this.initStaticAssets();
    };
    Application.prototype.initMenu = function () {
        new menu_1.Menu({
            collapseContainer: '.navbar-collapse-menu',
            togglerSelector: '.navbar-toggler-menu',
            isSubmenuCollapsed: true,
            expandedMenuItemSelector: '.mobile-menu__expanded-item',
            expandedMenuContainerSelector: '.mobile-menu__expanded_container',
            submenuContainerSelector: '.mobile-submenu__container'
        }).run();
    };
    Application.prototype.initSlider = function () {
        var sliderMain = document.querySelector('.main-slider');
        if (sliderMain !== null) {
            new slider_1.Slider({
                container: sliderMain,
                options: {
                    lazy: {
                        loadPrevNext: true,
                    },
                    autoplay: {
                        delay: 2500,
                        disableOnInteraction: false,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                    },
                }
            }).run();
        }
        var sliderMain_ar = document.querySelector('.main-slider-ar');
        if (sliderMain_ar !== null) {
            new slider_1.Slider({
                container: sliderMain_ar,
                options: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                    breakpoints: {
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        991: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                        1400: {
                            slidesPerView: 3,
                            spaceBetween: 60,
                        },
                        1800: {
                            slidesPerView: 3,
                            spaceBetween: 90,
                        },
                    },
                    autoplay: {
                        delay: 2500,
                        disableOnInteraction: false,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                }
            }).run();
        }
    };
    Application.prototype.initModal = function () {
        var template = {
            error: '<p class="fancybox-error">Ошибка загрузки данных.<br/>Пожалуйста попробуйте позже.</p>',
            closeBtn: '<a title="Закрыть" class="fancybox-item fancybox-close" href="javascript:;"></a>',
            next: '<a title="Следующий" class="fancybox-nav fancybox-next" href="javascript:;"><span></span></a>',
            prev: '<a title="Предыдущий" class="fancybox-nav fancybox-prev" href="javascript:;"><span></span></a>'
        };
        new modal_1.Modal({
            elementsSelector: '.fancybox__photo',
            options: {
                tpl: template
            }
        }).run();
        new modal_1.Modal({
            elementsSelector: '.fancybox__media',
            options: {
                tpl: template,
                helpers: {
                    media: {}
                }
            }
        }).run();
    };
    Application.prototype.initModalMedia = function () {
        new modal_media_1.ModalMedia({
            elementsSelector: '.fancybox__media',
            options: {
                tpl: this.generalModalTplOptions
            }
        }).run();
    };
    Application.prototype.initModalForm = function () {
        new modal_form_1.ModalForm({
            elementsSelector: '.fancybox__form',
            options: {
                tpl: {
                    error: '<p class="fancybox-error">Ошибка загрузки данных.<br/>Пожалуйста попробуйте позже.</p>',
                    closeBtn: '<a title="Закрыть" class="fancybox-item fancybox-close" href="javascript:;"></a>'
                },
                /*wrapCSS: 'fancybox-form',
                fitToView: true,*/
                /*afterShow: function(content) {
                    let container = document.querySelector('.fancybox-form');

                    if (container !== null) {
                        let form = container.querySelector('.form');
                        if (form !== null) {

                        }
                    }

                    var $form = $('.fancybox-form').find('form'),
                        $reCaptcha = $form.find('.g-recaptcha');

                    if (typeof grecaptcha != 'undefined' && $reCaptcha.length) {
                        var recaptchaWidgetId = grecaptcha.render($reCaptcha.get(0), {
                            'sitekey': $reCaptcha.data('sitekey')
                        });

                        $form.attr('data-widget-id', recaptchaWidgetId);
                    }

                    if ($.fn.validate) {
                        $form.validate({
                            ignore: '.ignore',
                            showErrors: app.defaultShowErrors,
                            submitHandler: app.ajaxForms.send
                        });
                    }
                },*/
                width: 400,
                helpers: {
                    overlay: {
                        locked: true
                    }
                }
            }
        }).run();
    };
    Application.prototype.initYmaps = function () {
        var items = document.querySelectorAll('.ymaps__container');
        items.forEach(function (item) {
            var element = item;
            var map = new ymaps_1.YMaps({
                container: element,
                options: {
                    center: [55.756462, 37.650262],
                    zoom: 17,
                    controls: ['smallMapDefaultSet']
                }
            }).run();
            // map.disableBehaviors('scrollZoom');
        });
    };
    Application.prototype.initScrollTo = function () {
        var items = document.querySelectorAll('.scroll__btn');
        items.forEach(function (item) {
            var element = item;
            if (element.dataset.scrollHref) {
                var scrollTarget = document.querySelector(element.dataset.scrollHref);
                if (scrollTarget !== null) {
                    new scroll_to_1.ScrollTo({
                        triggerElement: item,
                        destination: scrollTarget,
                        ease: ease_component_1.inOutSine,
                        scrollDuration: element.dataset.scrollDuration ? parseInt(element.dataset.scrollDuration) : 1000
                    }).run();
                }
            }
        });
    };
    Application.prototype.initFromValidation = function () {
        var items = document.querySelectorAll('form:not(.no-validate)');
        items.forEach(function (item) {
            new form_validation_1.FormValidation({
                element: item
            }).run();
        });
    };
    Application.prototype.initStaticAssets = function () {
        new static_assets_1.StaticAssets().run();
    };
    return Application;
}());
exports.Application = Application;
